import { MenuItemsInterface } from "../../interfaces/interface";

const constMenuItems: Array<MenuItemsInterface> = [
        {
            title: 'ERP de Gestão',
            url: '/simob',
            submenus: [
                {
                    title: 'Simob Locação',
                    url: '/simob#simob-locacao',
                },
                {
                    title: 'Simob Venda',
                    url: '/simob#simob-venda',
                },
                {
                    title: 'Simob Financeiro',
                    url: '/simob#simob-financeiro',
                },
                {
                    title: 'Simob Condomínio',
                    url: '/simob#simob-condominio',
                },
                {
                    title: 'Simob Renegociações',
                    url: '/simob#simob-renegociacao',
                },
                {
                    title: 'Simob Business Intelligence',
                    url: '/simob#simob-bi',
                },
                {
                    title: 'Simob Caixa',
                    url: '/simob#simob-caixa',
                },
                {
                    title: 'Sitouch',
                    url: '/simob#simob-sitouch',
                },
                {
                    title: 'Área do cliente',
                    url: '/simob#simob-area-do-cliente',
                }
            ]
        },
        {
            title: 'Site + CRM',
            url: '/moov',
            submenus: [
                {
                    title: 'Moov CRM',
                    url: '/moov#moov-crm',
                },
                {
                    title: 'Moov Site',
                    url: '/moov#moov-site',
                }
            ]
        },
        {
            title: 'Aplicativos',
            url: '/app',
            submenus: [
                {
                    title: 'Flexpro Tools',
                    url: '/app#flexpro-tools',
                },
                {
                    title: 'Flexpro Sitalk',
                    url: '/app#flexpro-sitalk',
                }
            ]
        },
        {
            title: 'Certificado Digital',
            url: '/certificado-digital'
        },
        {
            title: 'Conteúdos',
            url: 'https://flexpro.com.br/blog/',
            target: '_blank'
            // submenus: [
            //     {
            //         title: 'Blog',
            //         url: 'https://flexpro.com.br/blog/',
            //         target: '_blank',
            //     },
            //     {
            //         title: 'Youtube',
            //         url: 'https://www.youtube.com/@FlexproSistemas',
            //         target: '_blank',
            //     },
            //     {
            //         title: 'E-Book',
            //         url: '/conteudos#ebook',
            //     }
            // ]
        },
        // {
        //     title: 'Trabalhe conosco',
        //     url: '/trabalhe-conosco'
        // },
        {
            title: 'Contato',
            url: '/contato',
            submenus: [
                {
                    title: 'Fale Conosco',
                    url: '/contato',
                },
                {
                    title: 'Quem Somos',
                    url: '/sobre-nos#conheca-flexpro',
                }
            ]
        },
        // {
        //     title: 'Login',
        //     url: 'https://enet.simob.com.br/login/93e0f5eabf8b4a349663e08bfa2f15b47',
        //     target: '_blank'
        // }
    ];

export default constMenuItems;