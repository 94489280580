import { HelmetProvider } from 'react-helmet-async';
import RouteManager from './_config/routes/RouteManager';
import './App.css';

function App() {

  function openWhats() {
    var numero = "+554936228784";
    var mensagem = "Olá, gostaria de mais informações!";
    var url = "https://wa.me/" + numero + "?text=" + encodeURIComponent(mensagem);
    window.open(url, "_blank");
  }

  return (
    <HelmetProvider>
      <img onClick={openWhats} src='../_assets/images/whatsAppPopup.png' className='whatsapp' />
      <RouteManager />
    </HelmetProvider>
  );
}

export default App;
