import { MutableRefObject, useEffect, useRef, useState } from 'react';
import Icon from '../../../components/icon/Icon';
import MenuItemsMobile from './menuItemsMobile/MenuItemsMobile';
import './MenuMobile.css';
import { Link } from 'react-router-dom';

function MenuMobile() {
    const [stateMenu, setStateMenu] = useState(false);
    const refMenuMobile = useRef() as MutableRefObject<HTMLDivElement>

    useEffect(() => {
        if (refMenuMobile.current) {
            if (stateMenu) {
                refMenuMobile.current.classList.add('openMenuMobile');
            } else {
                refMenuMobile.current.classList.add('closeMenuMobile');
            }
        }
    }, [stateMenu]);

    function updateStatusMenu() {
        setStateMenu(prevStateMenu => !prevStateMenu);
    }

    return (
        <div className={"menuMobileContainer"}>
            <div className={"flexlogoMenu"}>
                <Link to="/"><img src="../_assets/images/FlexLogo.png" alt="Flexpro logo" /></Link>
            </div>
            <div onClick={updateStatusMenu}>
                <Icon icon='menu' class='menuMobileIcon' />
            </div>

            <div className={`menuItemsContainer ${stateMenu ? 'openMenuMobile' : 'closeMenuMobile'}`} ref={refMenuMobile}>
                <MenuItemsMobile updateStatusMenu={updateStatusMenu} />
                <Icon icon='close' class="closeMenuIcon" onClick={updateStatusMenu} />
            </div>
        </div>
    );
}

export default MenuMobile;