import Icon from '../../../components/icon/Icon';
import constRedesSociaisRodape from '../../../_config/const/constRodape/constRedesSociaisRodape';
import './Social.css';
import Caption from '../../../components/fontSizes/caption/Caption';
import Texto from '../../../components/fontSizes/texto/Texto';
import { Link } from 'react-router-dom';

function Social(props?: { isMobile?: boolean, isLanding?: boolean }) {

    const urlAcate: string = 'https://www.acate.com.br/associados/associado/?a=AYKP1zYZrsrEGpsD7PjM6nxZR1FzQ1d6aE5pSHFRODBpMU5xZ3BBPT0';

    function openUrl(url: string) {
        window.open(url, '_blank');
    }

    const redesSociais = constRedesSociaisRodape.map((redeSocial, index) =>
        <Icon key={index} class="midiaSocial" onClick={() => openUrl(redeSocial.url)} colorCircle={'white'} color={'var(--darkGray)'} icon={redeSocial.icon} title={'Ir para o ' + redeSocial.title} />
    )

    return (
        <div className={'social'}>
            <div className='socialTitle'>
                <b><Texto texto={'Flexpro Sistemas Ltda.'} /></b>
                <Texto texto={'Empresa especializada em soluções inteligentes e inovadoras para a gestão imobiliária.'} />
            </div>
            {!props?.isMobile && <div className='midiasSociais'>
                {redesSociais}
            </div>}

            <div className={'bottomContent'}>
                {props?.isMobile &&
                    <div className={'politicaPrivacidadeTagContainer'}>
                        <div className={'politicaPrivacidadeTag'}>
                            <Link to={'/politica-privacidade#termos-de-uso'} target={props.isLanding ? "_blank" : "_self"}><Caption texto={'Nossos Termos'} /></Link>
                        </div>
                        <div className={'politicaPrivacidadeTag'}>
                            <Link to={'/politica-privacidade#politica-privacidade'} target={props.isLanding ? "_blank" : "_self"}><Caption texto={'Política de Privacidade'} /></Link>
                        </div>
                    </div>}
                <img src='../_assets/images/selo_ACATE_2025.png' alt="Empresa associada ACATE" onClick={() => openUrl(urlAcate)} />
            </div>
        </div>
    )
}

export default Social;